<template>
  <div class="row">
    <div class="col-md-8">
      <MessageList :messages="chat.messages" />
    </div>
    <div class="col-md-4">
      <QuestionList :questions="chat.questions" />
    </div>

  </div>
</template>

<script>
import MessageList from '@/components/MessageList';
import QuestionList from '@/components/QuestionList';
export default {
  components: {QuestionList, MessageList},
  props: ['chat'],
};
</script>

<style scoped>
.message {
  margin-bottom: 10px;
}
.user {
  font-weight: bold;
}
</style>

<template>
  <div>
    <h2>Messages</h2>
    <div v-for="(message, index) in messages" :key="index" class="message">
      <p :class="{ user: message.is_user }">{{ message.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: Array,
  },
};
</script>

<style scoped>
.message {
  margin-bottom: 10px;
  font-weight: bold;
}
.user {
  font-weight: unset;
  opacity: 0.3;
}
</style>

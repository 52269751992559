<template>
  <div>
    <h2>Selected Questions</h2>
    <ul>
      <li v-for="(question, index) in questions" @click="removeQuestion(index)" :key="index">{{ question }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    questions: Array,
  },
  methods:{
    removeQuestion(index){
      window.removeQuestion(index)
    }
  }
};
</script>

<template>
  <div id="app" class="container">
    <div class="row">
      <h1 @click="getContent">Question Highlighter</h1>
    </div>

    <chat-entity v-for="(chat, index) in chats" :key="index" :chat="chat" @mouseenter="selected=index"></chat-entity>
  </div>
</template>

<script>
import ChatEntity from '@/components/ChatEntity';

export default {
  components: {
    ChatEntity
  },
  data() {
    return {
      chats     : [],
      selected  : undefined,
      fileHandle: undefined
    };
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
    window.removeQuestion = this.removeQuestion
    // this.getContent()

    // fetch('/questions.json').then(response => response.json()).then(data => {
    //   this.chats = data.map(x => {
    //     if (!x.questions) {
    //       let questions = [];
    //       let found     = window.localStorage.getItem(`question_${x.chat_id}`);
    //       if (found) {
    //         questions = JSON.parse(found);
    //       }
    //       x.questions = questions;
    //     }
    //     return x
    //   });
    // })
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    async getContent() {
      try {
        [this.fileHandle] = await window.showOpenFilePicker(
            {
              types                 : [
                {
                  description: 'JSON',
                  accept     : {
                    'application/json': ['.json']
                  }
                }
              ],
              excludeAcceptAllOption: true,
              multiple              : false
            })
        const file        = await this.fileHandle.getFile();
        const contents    = await file.text();
        if (!contents) {
          return
        }

        let data   = JSON.parse(contents)
        this.chats = data.map(x => {
          if (!x.questions) {
            let questions = [];
            let found     = window.localStorage.getItem(`question_${x.chat_id}`);
            if (found) {
              questions = JSON.parse(found);
            }
            x.questions = questions;
          }
          return x
        });
      } catch (err) {
        console.error(err)
      }
    },
    async saveFile() {
      // Записываем изменения обратно в файл
      const writable = await this.fileHandle.createWritable();
      console.log(JSON.stringify(this.chats, null, 2))
      await writable.write(JSON.stringify(this.chats, null, 2));
      await writable.close();
    },
    handleKeydown(event) {
      if (event.ctrlKey && event.key === 'q') {
        const selectedText = window.getSelection().toString();
        if (selectedText) {
          this.addQuestion(selectedText);
        }
      }
    },
    removeQuestion(index) {
      if (this.chats[this.selected].questions[index]) {
        this.chats[this.selected].questions.splice(index, 1)
        this.saveFile()
      }
    },
    addQuestion(text) {
      if (this.selected === undefined) {
        alert('not selected')
        return
      }
      if (!this.chats[this.selected].questions.includes(text)) {
        this.chats[this.selected].questions.push(text);
        localStorage.setItem(`question_${this.chats[this.selected].chat_id}`, JSON.stringify(this.chats[this.selected].questions))

        this.saveFile()
      }
    }
  }
};
</script>

<style>
body {
  font-family : Arial, sans-serif;
}
</style>
